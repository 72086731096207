// ==============================
// * PAGE DESIGNER: BRANDS BANNER CAROUSEL
//
// ? This file gathers all the brands banner carousel CSS.
// ==============================

@import "../../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "../commerceLayouts/slider";


.brands-banner-carousel {
    &__wrapper {
        padding: $grid-gutter-width $grid-gutter-width / 2 !important; // !important to override base sliders style

        @include media-breakpoint-up(lg) {
            padding: $grid-gutter-width * 2 0 !important; // !important to override base sliders style
        }

        .slick-slider {
            margin-bottom: 0;
            padding-inline-start: 0;

            @include media-breakpoint-up(lg) {
                padding: 0 $grid-gutter-width * 4;
            }

            li {
                width: auto;
                margin: 0 $grid-gutter-width / 2;
                text-align: center;
                text-transform: uppercase;
                font-size: rem(16px);

                @include media-breakpoint-up(md) {
                    font-size: rem(18px);
                }

                @include media-breakpoint-up(lg) {
                    margin: 0 $grid-gutter-width;
                    font-size: rem(22px);
                }

                a {
                    text-decoration: none;
                }
            }
        }

        .slick-arrow {
            top: 50% !important;

            &.slick-prev {
                left: $grid-gutter-width;
                transform: translateY(-50%) rotate(90deg) !important; // !important to override base sliders style
            }

            &.slick-next {
                right: $grid-gutter-width;
                transform: translateY(-50%) rotate(-90deg) !important; // !important to override base sliders style
            }
        }
    }
}
